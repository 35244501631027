/* General reset */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Container for the step */
.step-container {
  width: 100%;
  max-width: 100%;
  margin: 50px auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 70vh;
  box-sizing: border-box;
  position: relative;
}

/* Question labels */
.question-label {
  font-size: 0.9rem;
  margin-bottom: 10px;
  display: block;
  color: #6c757d;
  line-height: 1.5;
  white-space: pre-wrap;
}

/* Input styles */
.text-input {
  width: 95%;
  padding: 12px;
  margin-top: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  height: 120px;
}

/* Options group */
.options-group label {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.options-group input[type="radio"],
.options-group input[type="checkbox"] {
  margin-right: 15px;
  transform: scale(1.3);
}

/* Hover and active states for options */
.options-group input[type="radio"]:checked + label,
.options-group input[type="checkbox"]:checked + label {
  background-color: #eef6f9;
  border-color: #007bff;
}

/* Navigation buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  padding: 20px;
  gap: 20px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Summary sections */
.summary-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}

.summary-column-left, .summary-column-right {
  flex: 1;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
  max-height: 70vh;
  overflow-y: auto;
}

.summary-column-left {
  margin-right: 10px;
}

.summary-column-right {
  margin-left: 10px;
}

.summary-section {
  margin-bottom: 30px;
}

.summary-section h4 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #007bff;
}

.summary-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.summary-section li {
  margin-bottom: 12px;
  font-size: 1.1em;
  color: #333;
  line-height: 1.5;
}

.summary-section li strong {
  color: #007bff;
}

.ai-insights {
  margin-top: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
  line-height: 1.5;
  color: #333;
}

/* Chat bubble styling */
.ai-question-container,
.user-response {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.robot-avatar {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.robot-icon,
.user-icon {
  font-size: 40px;
  color: #007bff;
}

.ai-question-bubble,
.user-input-bubble {
  padding: 15px;
  border-radius: 15px;
  border: 2px solid #ccc;
  font-size: 1.1rem;
  width: 85%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.user-input-bubble {
  margin-right: 50px;
  margin-left: 80px;
}

.user-input-bubble:focus {
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.4);
  outline: none;
}

/* Responsive design */
@media (max-width: 767px) {
  .step-container {
    margin: 20px auto;
    padding: 20px;
    height: auto;
    width: 95%;
    box-shadow: none;
  }

  .options-group label {
    flex-direction: column;
    gap: 5px;
    font-size: 0.9rem;
  }

  .navigation-buttons {
    position: relative;
    margin-top: 20px;
  }

  button {
    padding: 10px 15px;
    font-size: 0.9rem;
  }

  .summary-container {
    flex-direction: column;
  }

  .summary-column-left, .summary-column-right {
    margin: 0 0 20px 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .step-container {
    padding: 25px;
    height: 65vh;
  }

  .options-group label {
    padding: 12px;
    font-size: 1rem;
  }

  .navigation-buttons {
    bottom: 20px;
  }

  button {
    padding: 12px 20px;
    font-size: 1rem;
  }
}

@media (min-width: 1025px) {
  .step-container {
    height: 70vh;
    padding: 30px;
  }

  .options-group label {
    padding: 15px;
    font-size: 1.1rem;
  }

  .navigation-buttons {
    bottom: 30px;
  }

  button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}
.insight-button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  width: 100%;
}

.insight-button:hover {
  background-color: #0056b3;
}
.additional-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.additional-button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.additional-button:hover {
  background-color: #218838;
}
.button-row {
  display: flex;
  align-items: center;
  gap: 10px;  /* Add spacing between the buttons */
  margin-top: 20px;
}

.main-button,
.additional-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-button:hover,
.additional-button:hover {
  background-color: #0056b3;
}

.main-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
/* Non-bullet, non-button insight */
.non-bullet-insight {
  font-size: 1rem;
  color: #333;
  padding: 10px 0;
  display: inline-block;
}

/* Style for the other bullet points with buttons */
.insight-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
}

.insight-button:hover {
  background-color: #0056b3;
}

/* No default bullets for the list */
.ai-insights ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ai-insights li {
  margin-bottom: 10px; /* Add space between list items */
}
