.custom-slider-container {
    position: relative;
    width: 100%;
    margin-bottom: 80px;
    margin-top: 80px;
  }
  
  .slider {
    width: 100%;
    margin: 10px 0;
    -webkit-appearance: none;
    appearance: none;
    height: 6px;
    background: #ddd;
    border-radius: 5px;
    outline: none;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: green; /* Change slider thumb color to green */
    border-radius: 50%;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: green; /* Change slider thumb color to green */
    border-radius: 50%;
    cursor: pointer;
  }
  
  .slider-value-display {
    position: absolute;
    top: -30px; /* Position it above the slider thumb */
    transform: translateX(-50%); /* Center it horizontally */
    font-size: 16px;
    font-weight: bold;
    color: green; /* Change number label color to green */
  }
  
  .scale {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  
  .tick {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tick-mark {
    width: 2px;
    height: 10px;
    background-color: #000;
    margin-bottom: 5px;
  }
  
  .tick-label {
    font-size: 12px;
    margin-top: 5px;
  }
  
  
  .security-message {
    margin-top: 20px; /* Add margin to create space above the message */
    font-size: 14px;
    color: #555555;
    text-align: center;
  }
  