/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); /* Importing Google Font */

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; /* Ensure 'Roboto' is the primary font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; /* Keeping the monospaced font for code blocks */
}

/* Ensure all form elements inherit the global font settings */
input,
textarea,
button,
select {
  font-family: inherit; /* Inherit the font from body */
  font-size: inherit;   /* Inherit the font size as well */
}

input::placeholder,
textarea::placeholder {
  font-family: inherit; /* Ensure the placeholder text inherits the font */
  font-size: inherit;   /* Ensure the placeholder text inherits the font size */
}
