.master-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between; /* Space between logo-nav and icons */
  align-items: center; /* Align items in the center vertically */
}

.logo-nav {
  display: flex;
  align-items: flex-end; /* Ensure logo and nav links are bottom-aligned */
}

.logo {
  display: flex;
  flex-direction: column;
  position: relative; /* Allows for positioning of the subscript */
}

.logo a {
  color: white;
  font-size: 1.5rem;
  text-decoration: none;
  display: inline-block;
  line-height: 1.5;
}

.logo-subscript {
  position: absolute;
  bottom: -10px; /* Adjusts subscript to be below the logo */
  right: 0; /* Aligns the subscript to the right */
  font-size: 0.8rem;
  color: #dcdcdc;
  line-height: 1.5;
}

.nav-links {
  display: flex;
  margin-left: 20px; /* Space between logo and nav links */
}

.nav-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  line-height: 1;
  display: inline-block;
  vertical-align: bottom; /* Ensure nav links align with bottom */
}

.navdisabled-links {
  display: flex;
  margin-left: 20px; /* Space between logo and nav links */
  color: #0c3844;
  margin: 0 10px;
  text-decoration: none;
  line-height: 1;
  display: inline-block;
  vertical-align: bottom;
}

.icons {
  display: flex;
  align-items: center;
}

.icon-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 20px; /* Space between icon containers */
  margin-right: 20px;
}

.icon-link {
  display: flex;
  justify-content: center;
}

.icon {
  font-size: 1.5rem;
  color: white;
}

.icon-label {
  margin-top: 5px;
  font-size: 0.75rem;
  color: white;
  text-align: center;
}

.profile-container {
  position: relative;
  cursor: pointer;
}

.profile-popup {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 200px;
  border-radius: 5px;
  text-align: center;
}

.profile-details {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}

.logout-button {
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.logout-button:hover {
  background-color: #0056b3;
}

.main-content {
  flex-grow: 1;
}

.footer {
  background-color: #007bff;
  color: white;
  text-align: center;
  padding: 10px;
}

/* Mobile responsiveness */
@media only screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo-nav {
    margin-bottom: 10px;
  }

  .logo a {
    font-size: 1.2rem;
  }

  .nav-links {
    margin-top: 10px;
    margin-left: 0;
  }

  .icons {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .icon-label-container {
    flex-direction: row;
    align-items: center;
  }

  .icon-label {
    margin-left: 8px;
    margin-top: 0;
  }
}
.screen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Makes the container take up the full viewport height */
}

.coming-soon-container h2 {
  font-size: 24px;
  font-weight: bold;
}
/* Default link and icon styles */
.nav-link {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  line-height: 1;
  display: inline-block;
  vertical-align: bottom;
  padding: 5px 10px;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: grey;
}

/* Active link styles */
.nav-link.active {
  color: rgba(15, 241, 68, 0.808); /* Change active text color to grey */
}

/* Icon container styles */
.icon-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 20px;
  transition: color 0.3s ease;
}

.icon-label-container .icon {
  font-size: 1.5rem;
  color: white; /* Default icon color */
}

.icon-label-container.active .icon {
  color:rgba(15, 241, 68, 0.808); /* Change active icon color to grey */
}

/* Icon label active state */
.icon-label-container.active .icon-label {
  color: rgba(15, 241, 68, 0.808); /* Change active text color to grey */
}

.icon-label {
  margin-top: 5px;
  font-size: 0.75rem;
  color: white; /* Default icon label color */
  text-align: center;
}

.icon-label-container.active {
  color:rgba(15, 241, 68, 0.808); /* Change active icon label color to grey */
}
/* Default link and icon styles */
.nav-link {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  line-height: 1;
  display: inline-block;
  vertical-align: bottom;
  padding: 5px 10px;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: grey;
}

/* Active link styles */
.nav-link.active {
  color: rgba(15, 241, 68, 0.808); /* Change active text color to grey */
}

/* Icon container styles */
.icon-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 20px;
  transition: color 0.3s ease;
}

/* Default icon color */
.icon-label-container .icon {
  font-size: 1.5rem;
  color: white;
  transition: color 0.3s ease;
}

/* Hover effect for icons */
.icon-label-container:hover .icon {
  color: grey; /* Change icon color on hover */
}

/* Active state for icons */
.icon-label-container.active .icon {
  color: rgba(15, 241, 68, 0.808); /* Active icon color */
}

/* Default icon label color */
.icon-label {
  margin-top: 5px;
  font-size: 0.75rem;
  color: white;
  text-align: center;
  transition: color 0.3s ease;
}

/* Hover effect for icon labels */
.icon-label-container:hover .icon-label {
  color: grey; /* Change label color on hover */
}

/* Active state for icon labels */
.icon-label-container.active .icon-label {
  color: rgba(15, 241, 68, 0.808); /* Active label color */
}

/* Active state for the entire icon-label-container */
.icon-label-container.active {
  color: rgba(15, 241, 68, 0.808);
}
