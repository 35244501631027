/* Style for the container */
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically centers content */
    align-items: center; /* Horizontally centers content */
    height: 100vh; /* Full viewport height */
    background-color: #f0f0f0; /* Light background color */
    text-align: center;
}

/* Style for the login buttons container */
.login-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between the buttons */
    width: 100%;
    max-width: 400px; /* Maximum width for the buttons */
    margin-top: 20px; /* Adds space between the text and buttons */
}

/* General login button styling for both <a> and <button> */
.login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    font-size: 1.2rem;
    color: white;
    background-color: #4285F4; /* Same background color for both buttons */
    border: none;
    border-radius: 5px;
    text-decoration: none; /* Ensures <a> looks like a button */
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%; /* Ensure both buttons take full width */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

/* Ensure <a> behaves like a button */
.login-button a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* Hover effect */
.login-button:hover {
    background-color: #357ae8; /* Darker shade of blue on hover */
}

/* Icon styling */
.login-icon {
    margin-right: 10px;
    font-size: 1.5rem;
}
