/* Main container for phone OTP login */
.phone-otp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f7fa;
}

/* Card container for inputs */
.phone-otp-card {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

/* Title styling */
.phone-otp-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
}

/* Input styling */
.input-field {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.input-field:focus {
    border-color: #4285F4;
    outline: none;
    box-shadow: 0 0 5px rgba(66, 133, 244, 0.5);
}

/* Button styling */
.otp-button {
    width: 100%;
    padding: 12px;
    background-color: #4285F4;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.otp-button:hover {
    background-color: #357ae8;
}

/* ReCaptcha container should be hidden */
#recaptcha-container {
    margin-top: 10px;
}

/* Optional styling for the OTP message */
.otp-message {
    color: #34a853;
    margin-bottom: 20px;
    font-size: 1rem;
}
