.progress-bar {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}

.progress-step {
  flex: 1;
  text-align: center;
  /* padding: 10px; */
  position: relative;
  /* font-weight: bold; */
  font-size: 1rem;
  color: #333;
  border-bottom: 1px solid #ccc;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.progress-step::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  /* height: 3px; */
  width: 100%;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.progress-step.active {
  color: #007bff;
  border-bottom-color: #007bff;
}

.progress-step.completed {
  color: #007bff;
  border-bottom-color: #007bff;
}

.progress-step.completed::before {
  background-color: #007bff;
}

.progress-step:not(.active):hover {
  color: #007bff;
  cursor: pointer;
}

/* Mobile specific styling */
@media (max-width: 767px) {
  .progress-bar {
    flex-direction: column;
    align-items: stretch;
  }

  .progress-step {
    /* padding: 15px 0; */
    border-left: 2px solid #ccc;
    border-bottom: none;
  }

  .progress-step.active, .progress-step.completed {
    border-left-color: #007bff;
  }

  .progress-step.completed::before {
    background-color: #007bff;
  }

  .progress-step::before {
    height: 100%;
    width: 3px;
    bottom: 0;
    left: -2px;
  }
}
