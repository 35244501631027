.pfs-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
}

.pfs-content {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto; /* Allow scrolling for long content */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  justify-content: space-between;
}

.progress-step {
  flex: 1;
  padding: 10px;
  text-align: center;
  font-size: 1rem;
  margin-right: 5px;
  border-radius: 4px;
}

.progress-step.completed {
  background-color: #007bff;
  color: white;
}

.step-navigator {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.nav-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nav-button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}
.security-message {
  text-align: center;
  font-size: 0.7rem;
  color: #333;
  /* background-color: #f5f5f5; */
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  color: rgb(12, 156, 77);
}
