.App {
  text-align: center;
}

.App-logo {
  height: 30vmin; /* Smaller for mobile devices */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin); /* Smaller font for mobile */
  color: white;
}

.App-link {
  color: #61dafb;
}

@media only screen and (max-width: 768px) {
  .App-header {
    font-size: calc(7px + 2vmin); /* Scale font down for smaller screens */
  }

  .App-logo {
    height: 25vmin;
  }
}
