.home-screen {
  text-align: center;
  padding: 2rem;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 1rem; /* Smaller gap on mobile */
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
}

.home-button {
  padding: 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 150px; /* Ensure touch-friendly size */
}

.home-button:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 768px) {
  .button-container {
    gap: 0.5rem; /* Reduce gap further for small screens */
  }
  
  .home-button {
    padding: 0.8rem;
    font-size: 0.9rem; /* Reduce button size slightly */
  }
}
.home-screen {
  text-align: center;
  padding: 20px;
}

.greeting {
  font-size: 2em;
  margin-bottom: 40px;
  margin-top: 20px;
}
.robot-icon{
  margin-bottom: 10px;
  margin-top: 40px;
}

.intro-text {
  font-size: 1.2em;
  margin-bottom: 40px;
}

.features-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
}

.feature-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 300px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: left;
}

.feature-icon {
  font-size: 2em;
  color: #4CAF50;
  margin-bottom: 10px;
}

.feature-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 1em;
  flex-grow: 1; /* Make the description grow to fill space */
  margin-bottom: 15px;
}

.cta-button {
  align-self: flex-start; /* Align button to the start */
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: auto; /* Ensure button sticks to the bottom */
}

.cta-button:hover {
  background-color: #45a049;
}

@media only screen and (max-width: 768px) {
  .features-container {
    flex-direction: column;
    align-items: center;
  }
}
